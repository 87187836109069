<section class="container">
    <div class="mobileFooterContainer">
        <div class="footerLogoContainerMobile">
            <div class="footerLogoMobile"></div>
            <span>Web Developer</span>
            <span>Erfurt Germany</span>
        </div>
    
        <div class="footerLinksContainerMobile">
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="https://github.com/Justin-Bruder" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">Github</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="https://www.linkedin.com/in/justin-bruder-2ba04733b/" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">LinkedIn</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="mailto:info@justinbruder.de" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">Email</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a routerLink="/legal-notice" style="text-decoration: none; color: #d9d9d9;">
                    Legal Notice
                </a>
            </div>
        </div>
    </div>

    <div class="footerContainer">
        <div class="footerLogoContainer">
            <div class="footerLogo"></div>
            <span>Web Developer</span>
            <span>Erfurt Germany</span>
        </div>

        <div>
            <span class="copyrightContainer">
                © Justin Bruder 2024
            </span>
        </div>

        <div class="footerLinksContainer">
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="https://github.com/Justin-Bruder" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">Github</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="https://www.linkedin.com/in/justin-bruder-2ba04733b/" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">LinkedIn</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a href="mailto:info@justinbruder.de" style="text-decoration: none; color: #d9d9d9;">
                    <span class="spanWhite">Email</span>
                </a>
            </div>
            <div>
                <img src="assets/img/smallGreenDotHover.png">
                <a routerLink="/legal-notice" style="text-decoration: none; color: #d9d9d9;">
                    Legal Notice
                </a>
            </div>
        </div>
    </div>
</section>
