<section id="contactMe-section" class="container">
  <div class="contactMeTextContainer">
    <h2>{{ isEnglish ? 'Contact me' : 'Kontakt aufnehmen' }}</h2>
    <h1>{{ isEnglish ? "Let's work together" : 'Teamarbeit' }}</h1>
    <h3>{{ isEnglish ? 'Got a Problem to solve?' : 'Haben Sie ein Problem zu lösen?' }}</h3>
    <p>
      {{ isEnglish 
        ? 'Contact me through this form, I am interested in hearing from you, knowing your ideas and contributing to your projects with my work.' 
        : 'Kontaktieren Sie mich über dieses Formular. Ich bin gespannt auf Ihre Ideen und freue mich darauf, mit meiner Arbeit zu Ihren Projekten beizutragen.' }}
    </p>
    <p>
      <span class="spanWhite">{{ isEnglish ? 'Need a Frontend developer?' : 'Benötigen Sie einen Frontend-Entwickler?' }}</span>
      <span class="spanGreen">{{ isEnglish ? " Let's talk!" : ' Lass uns reden!' }}</span>
    </p>
  </div>

  <div class="formContainer">
    <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
      <div class="singleInputContainer">
        <h3 class="h3FormContainer">{{ isEnglish ? "What's your name?" : 'Wie heißen Sie?' }}</h3>
        <input 
          minlength="4" 
          #name="ngModel" 
          [(ngModel)]="contactData.name" 
          name="name" 
          [placeholder]="isEnglish ? 'Your name goes here' : 'Ihr Name'" 
          required>
        <span class="spanFormContainer" *ngIf="!name.valid && name.touched">
          {{ isEnglish ? 'Oops! it seems your name is missing' : 'Hoppla! Ihr Name fehlt' }}
        </span>
      </div>

      <div class="singleInputContainer">
        <h3 class="h3FormContainer">{{ isEnglish ? "What's your email?" : 'Wie lautet Ihre E-Mail?' }}</h3>
        <input 
          minlength="4" 
          #email="ngModel" 
          [(ngModel)]="contactData.email" 
          name="email" 
          [placeholder]="isEnglish ? 'youremail@email.com' : 'Ihreemail@email.com'" 
          required 
          pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}">
        <span class="spanFormContainer" *ngIf="!email.valid && email.touched">
          {{ isEnglish ? 'Oops! your email is required' : 'Hoppla! Ihre E-Mail ist erforderlich' }}
        </span>
      </div>

      <div class="singleInputContainer">
        <h3 class="h3FormContainer">{{ isEnglish ? 'How can I help you?' : 'Wie kann ich Ihnen helfen?' }}</h3>
        <textarea 
          minlength="4" 
          #message="ngModel" 
          [(ngModel)]="contactData.message" 
          name="message" 
          [placeholder]="isEnglish ? 'Hello Justin, I am interested in...' : 'Hallo Justin, ich bin interessiert an...'" 
          required></textarea>
        <span class="spanFormContainer" *ngIf="!message.valid && message.touched">
          {{ isEnglish ? 'What do you need to develop?' : 'Was möchten Sie entwickeln?' }}
        </span>
      </div>

      <div class="checkBoxContainer">
        <div class="custom-checkbox" (click)="toggleCheck()" [class.checked]="isChecked"></div>
        <p>
          <span class="spanWhite">{{ isEnglish ? "I've read the " : 'Ich habe die ' }}</span>
          <a routerLink="/privacy-policy">{{ isEnglish ? 'privacy policy' : 'Datenschutzrichtlinie' }}</a>
          <span class="spanWhite">{{ isEnglish ? ' and agree to the processing of my data as outlined' : ' gelesen und stimme der Verarbeitung meiner Daten zu' }}</span>
        </p>
      </div>

      <span class="spanFormContainer" *ngIf="!isChecked && formSubmitted">
        {{ isEnglish ? 'Please accept the privacy policy' : 'Bitte akzeptieren Sie die Datenschutzrichtlinie' }}
      </span>

      <div class="buttonContainer">
        <button type="submit" [disabled]="!contactForm.valid || !isChecked">
          <span>{{ isEnglish ? 'Say Hello' : 'Hallo sagen' }}</span>
        </button>
        <div *ngIf="feedbackMessage" [ngClass]="{'feedbackMessage success': feedbackSuccess, 'feedbackMessage error': !feedbackSuccess}">
          {{ feedbackMessage }}
        </div>        
      </div>
    </form>
  </div>
</section>
