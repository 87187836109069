<div class="backgroundContainerPrivacyPolicy">
  <div class="container-limit">
    <app-navbar [isEnglish]="isEnglish" (languageChanged)="isEnglish = $event"></app-navbar>
    <section>
      <h1>{{ isEnglish ? 'Privacy Policy' : 'Datenschutzerklärung' }}</h1>
      <p>{{ isEnglish ? 'Last updated: November 19, 2024' : 'Stand: 19. November 2024' }}</p>
      
      <h2>{{ isEnglish ? 'Table of Contents' : 'Inhaltsübersicht' }}</h2>
      <ul class="index">
        <li><span class="index-link">{{ isEnglish ? 'Controller' : 'Verantwortlicher' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Overview of Processing' : 'Übersicht der Verarbeitungen' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Legal Basis' : 'Maßgebliche Rechtsgrundlagen' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Security Measures' : 'Sicherheitsmaßnahmen' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Transfer of Personal Data' : 'Übermittlung von personenbezogenen Daten' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'International Data Transfers' : 'Internationale Datentransfers' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'General Information on Data Retention and Deletion' : 'Allgemeine Informationen zur Datenspeicherung und Löschung' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Data Subject Rights' : 'Rechte der betroffenen Personen' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Providing Online Services and Web Hosting' : 'Bereitstellung des Onlineangebots und Webhosting' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Use of Cookies' : 'Einsatz von Cookies' }}</span></li>
        <li><span class="index-link">{{ isEnglish ? 'Contact and Inquiry Management' : 'Kontakt- und Anfrageverwaltung' }}</span></li>
      </ul>
      
      <h2 id="m3">{{ isEnglish ? 'Controller' : 'Verantwortlicher' }}</h2>
      <p>Justin Bruder<br>Pfarrgasse 12,<br>99189 Gebesee</p>
      <p>
        {{ isEnglish ? 'Email Address:' : 'E-Mail-Adresse:' }} 
        <a href="mailto:info&#64;justinbruder.de">info&#64;justinbruder.de</a>
      </p>
      <p>{{ isEnglish ? 'Phone: 01747639730' : 'Telefon: 01747639730' }}</p>
      
      <h2 id="mOverview">{{ isEnglish ? 'Overview of Processing' : 'Übersicht der Verarbeitungen' }}</h2>
      <p>{{ isEnglish ? 'The following overview summarizes the types of processed data and the purposes of processing and refers to the affected individuals.' : 'Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.' }}</p>
      <h3>{{ isEnglish ? 'Types of Processed Data' : 'Arten der verarbeiteten Daten' }}</h3>
      <ul>
        <li>{{ isEnglish ? 'Inventory data' : 'Bestandsdaten' }}.</li>
        <li>{{ isEnglish ? 'Contact data' : 'Kontaktdaten' }}.</li>
        <li>{{ isEnglish ? 'Content data' : 'Inhaltsdaten' }}.</li>
        <li>{{ isEnglish ? 'Usage data' : 'Nutzungsdaten' }}.</li>
        <li>{{ isEnglish ? 'Meta/communication data' : 'Meta-, Kommunikations- und Verfahrensdaten' }}.</li>
        <li>{{ isEnglish ? 'Log data' : 'Protokolldaten' }}.</li>
      </ul>
      <h3>{{ isEnglish ? 'Categories of Affected Individuals' : 'Kategorien betroffener Personen' }}</h3>
      <ul>
        <li>{{ isEnglish ? 'Communication partners' : 'Kommunikationspartner' }}.</li>
        <li>{{ isEnglish ? 'Users' : 'Nutzer' }}.</li>
      </ul>
      <h3>{{ isEnglish ? 'Purposes of Processing' : 'Zwecke der Verarbeitung' }}</h3>
      <ul>
        <li>{{ isEnglish ? 'Communication' : 'Kommunikation' }}.</li>
        <li>{{ isEnglish ? 'Security measures' : 'Sicherheitsmaßnahmen' }}.</li>
        <li>{{ isEnglish ? 'Organizational and administrative tasks' : 'Organisations- und Verwaltungsverfahren' }}.</li>
        <li>{{ isEnglish ? 'Feedback' : 'Feedback' }}.</li>
        <li>{{ isEnglish ? 'Providing our online offering and user-friendliness' : 'Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit' }}.</li>
        <li>{{ isEnglish ? 'IT infrastructure' : 'Informationstechnische Infrastruktur' }}.</li>
      </ul>
  
      <h2 id="m2427">{{ isEnglish ? 'Legal Basis' : 'Maßgebliche Rechtsgrundlagen' }}</h2>
      <p>{{ isEnglish ? 'Below is an overview of the legal bases of the GDPR...' : 'Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO...' }}</p>
  
      <h2 id="m27">{{ isEnglish ? 'Security Measures' : 'Sicherheitsmaßnahmen' }}</h2>
      <p>{{ isEnglish ? 'We take technical and organizational measures...' : 'Wir treffen technische und organisatorische Maßnahmen...' }}</p>
  
      <h2 id="m25">{{ isEnglish ? 'Transfer of Personal Data' : 'Übermittlung von personenbezogenen Daten' }}</h2>
      <p>{{ isEnglish ? 'In the context of our processing of personal data...' : 'Im Rahmen unserer Verarbeitung von personenbezogenen Daten...' }}</p>
  
      <h2 id="m24">{{ isEnglish ? 'International Data Transfers' : 'Internationale Datentransfers' }}</h2>
      <p>{{ isEnglish ? 'Data processing in third countries is carried out in accordance with GDPR...' : 'Datenverarbeitung in Drittländern erfolgt gemäß der DSGVO...' }}</p>
  
      <h2 id="m12">{{ isEnglish ? 'General Information on Data Retention and Deletion' : 'Allgemeine Informationen zur Datenspeicherung und Löschung' }}</h2>
      <p>{{ isEnglish ? 'We delete personal data in accordance with legal requirements...' : 'Wir löschen personenbezogene Daten gemäß den gesetzlichen Vorgaben...' }}</p>
  
      <h2 id="m10">{{ isEnglish ? 'Rights of Data Subjects' : 'Rechte der betroffenen Personen' }}</h2>
      <p>{{ isEnglish ? 'Rights of data subjects under GDPR...' : 'Rechte der betroffenen Personen aus der DSGVO...' }}</p>
  
      <h2 id="m225">{{ isEnglish ? 'Providing Online Services and Web Hosting' : 'Bereitstellung des Onlineangebots und Webhosting' }}</h2>
      <p>{{ isEnglish ? 'We process user data to provide our online services...' : 'Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung zu stellen...' }}</p>
  
      <h2 id="m134">{{ isEnglish ? 'Use of Cookies' : 'Einsatz von Cookies' }}</h2>
      <p>{{ isEnglish ? 'We use cookies in accordance with legal requirements...' : 'Wir verwenden Cookies gemäß den gesetzlichen Vorschriften...' }}</p>
  
      <h2 id="m182">{{ isEnglish ? 'Contact and Inquiry Management' : 'Kontakt- und Anfrageverwaltung' }}</h2>
      <p>{{ isEnglish ? 'When contacting us...' : 'Bei der Kontaktaufnahme mit uns...' }}</p>
  
      <p class="seal">
        <a href="https://datenschutz-generator.de/" 
           title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." 
           target="_blank" 
           rel="noopener noreferrer nofollow">
           {{ isEnglish ? 'Generated with free privacy generator by Dr. Thomas Schwenke' : 'Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke' }}
        </a>
      </p>
    </section>
    <app-footer [isEnglish]="isEnglish"></app-footer>
  </div>
  </div>
  