<section id="about-section" class="container">
  <div class="pictureAndCamoContainer">
    <div class="camoContainer"></div>
    <div class="pictureContainer">
      <div class="glow-wrap">
        <i class="glow"></i>
      </div>
    </div>
  </div>

  <div class="aboutMeContainer">
    <h2>{{ isEnglish ? 'Who I Am' : 'Wer ich bin' }}</h2>

    <div class="textBoxAboutMe">
      <h1>{{ isEnglish ? 'About Me' : 'Über mich' }}</h1>

      <p>
        {{ isEnglish
          ? 'Hi, I’m a German-speaking Frontend Developer based in Erfurt. Motivated by the limitless opportunities within IT, I am excited about crafting visually captivating and intuitive websites and applications.'
          : 'Hallo, ich bin ein deutschsprachiger Frontend-Entwickler aus Erfurt. Motiviert durch die unbegrenzten Möglichkeiten in der IT, bin ich begeistert davon, visuell ansprechende und intuitive Websites und Anwendungen zu erstellen.' }}
      </p>

      <div class="AboutMeIconAndTextContainer">
        <div class="iconContainer">
          <img src="assets/img/locationIcon.png" alt="location icon">
        </div>
        <span>
          {{ isEnglish
            ? 'Flexible in terms of working environments, I can work effectively both on-site and remotely.'
            : 'Flexibel hinsichtlich der Arbeitsumgebung, kann ich sowohl vor Ort als auch remote effektiv arbeiten.' }}
        </span>
      </div>

      <div class="AboutMeIconAndTextContainer">
        <div class="iconContainer">
          <img src="assets/img/headIcon.png" alt="open-minded icon">
        </div>
        <span>
          {{ isEnglish
            ? 'I am open-minded and always looking for personal challenges to constantly improve my knowledge and skills.'
            : 'Ich bin aufgeschlossen und immer auf der Suche nach persönlichen Herausforderungen, um mein Wissen und meine Fähigkeiten ständig zu verbessern.' }}
        </span>
      </div>

      <div class="AboutMeIconAndTextContainer">
        <div class="iconContainer">
          <img src="assets/img/checkIcon.png" alt="problem-solving icon">
        </div>
        <span>
          {{ isEnglish
            ? 'In my profession, programming isn\'t just about writing code; it\'s a creative form of problem-solving. I take pride in my ability to distill complex technical challenges into simple, user-friendly solutions. This way, I help you achieve your goals and bring your visions to life.'
            : 'In meinem Beruf geht es beim Programmieren nicht nur darum, Code zu schreiben; es ist eine kreative Form der Problemlösung. Ich bin stolz auf meine Fähigkeit, komplexe technische Herausforderungen in einfache, benutzerfreundliche Lösungen zu übersetzen. So helfe ich Ihnen, Ihre Ziele zu erreichen und Ihre Visionen zum Leben zu erwecken.' }}
        </span>
      </div>
    </div>
  </div>
</section>
