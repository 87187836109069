<section>
  <div class="navbarLinks">
    <div class="languageSwitch" (click)="toggleLanguage()">
      <div
        class="generalDesignSingleSwitch"
        [ngClass]="{ 'backgroundGreen': isEnglish, 'backgroundBlack': !isEnglish }"
      >
        <p class="en">EN</p>
      </div>
      <div
        class="generalDesignSingleSwitch"
        [ngClass]="{ 'backgroundGreen': !isEnglish, 'backgroundBlack': isEnglish }"
      >
        <p class="de">DE</p>
      </div>
    </div>

    <a (click)="scrollToAboutMe()">
      <span *ngIf="isEnglish; else deAboutMe">About me</span>
      <ng-template #deAboutMe><span>Über mich</span></ng-template>
      <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
    </a>

    <a (click)="scrollToSkills()">
      <span *ngIf="isEnglish; else deSkills">Skills</span>
      <ng-template #deSkills><span>Fähigkeiten</span></ng-template>
      <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
    </a>

    <a (click)="scrollToProjects()">
      <span *ngIf="isEnglish; else deProjects">Projects</span>
      <ng-template #deProjects><span>Projekte</span></ng-template>
      <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
    </a>
  </div>

  <div class="navbarLogo" (click)="navigateToHome()" style="cursor: pointer;" alt="Logo"></div>

  <div class="navbarMobile">
    <div class="navbarLogoMobile"></div>
    <div class="navbarMenuIcon" (click)="showMenu()"></div>
  </div>

  <div class="menuOverlay" [ngClass]="{ 'd-show': isMenuVisible, 'd-none': !isMenuVisible }" (click)="closeMenu()">
    <div class="menuBox" (click)="$event.stopPropagation()">
      <a (click)="scrollToAboutMe()">
        <span *ngIf="isEnglish; else deAboutMe">About me</span>
        <ng-template #deAboutMe><span>Über mich</span></ng-template>
        <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
      </a>

      <a (click)="scrollToSkills()">
        <span *ngIf="isEnglish; else deSkills">Skills</span>
        <ng-template #deSkills><span>Fähigkeiten</span></ng-template>
        <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
      </a>

      <a (click)="scrollToProjects()">
        <span *ngIf="isEnglish; else deProjects">Projects</span>
        <ng-template #deProjects><span>Projekte</span></ng-template>
        <img src="assets/img/smallGreenDotHover.png" alt="Indicator">
      </a>

      <div class="languageSwitch" (click)="toggleLanguage()">
        <div
          class="generalDesignSingleSwitch"
          [ngClass]="{ 'backgroundGreen': isEnglish, 'backgroundBlack': !isEnglish }"
        >
          <p class="en">EN</p>
        </div>
        <div
          class="generalDesignSingleSwitch"
          [ngClass]="{ 'backgroundGreen': !isEnglish, 'backgroundBlack': isEnglish }"
        >
          <p class="de">DE</p>
        </div>
      </div>
    </div>
  </div>
</section>
