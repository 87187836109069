<section> 
    <h2>{{ isEnglish ? 'Frontend Developer' : 'Frontend-Entwickler' }}</h2>
    <h1>Justin Bruder</h1>
  
    <div class="containerButtonHeadline">
      <button (click)="scrollToProjects()" class="buttonHeadline">
        <span>{{ isEnglish ? 'Check my work' : 'Meine Arbeiten ansehen' }}</span>
      </button>
      
      <button (click)="scrollToContactMe()" class="buttonHeadline">
        <span>{{ isEnglish ? 'Contact me' : 'Kontakt aufnehmen' }}</span>
      </button>
    </div>
  </section>
  
