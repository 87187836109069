<div *ngIf="!isPrivacyPolicyPage && !isLegalNoticePage" class="backgroundContainer">
  <div class="cursor-shadow"></div>
  <app-header [isEnglish]="isEnglish" (languageChanged)="updateLanguage($event)"></app-header>
  <app-about-me [isEnglish]="isEnglish"></app-about-me>
  <app-skills [isEnglish]="isEnglish"></app-skills>
  <app-portfolio [isEnglish]="isEnglish"></app-portfolio>
  <app-contact-me [isEnglish]="isEnglish"></app-contact-me>
  <app-footer [isEnglish]="isEnglish"></app-footer>
</div>
<div>
  <router-outlet class="backgroundContainer"></router-outlet>
</div>

